<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="充值价格" prop="rechargePrice" >
        <a-input-number :min="1" v-model="form.rechargePrice" placeholder="请输入充值价格" />
      </a-form-model-item>
      <a-form-model-item label="充值钻石" prop="rechargeDiamond" >
        <a-input-number :min="1" v-model="form.rechargeDiamond" placeholder="请输入充值钻石" />
      </a-form-model-item>
<!--      <a-form-model-item label="优惠百分比%" prop="preferential" >-->
<!--        <a-input-number :min="1" :max="100" v-model="form.preferential" placeholder="请输入优惠百分比%" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="赠送钻石" prop="giveDiamond" >-->
<!--        <a-input-number :min="1" v-model="form.giveDiamond" placeholder="请输入赠送钻石"  />-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="ios内购产品ID" prop="productId" >-->
<!--        <a-input v-model="form.productId" placeholder="请输入ios内购产品ID"  />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRechargeMenu, addRechargeMenu, updateRechargeMenu } from '@/api/rechargeMenu/rechargeMenu.js'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',

      // 表单参数
      form: {
        id: null,

        rechargePrice: null,

        rechargeDiamond: null,

        productId: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        rechargePrice: [
          { required: true, message: '充值价格不能为空', trigger: 'blur' }
        ],
        rechargeDiamond: [
          { required: true, message: '充值钻石不能为空', trigger: 'blur' }
        ],
        preferential: [
          { required: true, message: '优惠百分比%不能为空', trigger: 'blur' }
        ],
        giveDiamond: [
          { required: true, message: '赠送钻石不能为空', trigger: 'blur' }
        ],
        productId: [
          { required: true, message: 'ios内购产品id不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        rechargePrice: null,
        rechargeDiamond: null,
        productId: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRechargeMenu({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRechargeMenu(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRechargeMenu(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
